<template>
    <setting-layout :title="$t('pages.user.setting.language.title')">

        <v-container>
            <v-row>
                <v-col>
                    <v-select
                        background-color="rgba(237,237,237, .1)"
                        color="orange"
                        :items="languages"
                        solo
                        flat
                        item-color="orange"
                        item-text="text"
                        item-value="code"
                        v-model="model"
                    ></v-select>
                </v-col>
            </v-row>
            <v-row justify="center" class="pt-0 pl-7 pr-7">
                <gradient-button block @click="done">{{$t('pages.user.setting.language.button')}}</gradient-button>
            </v-row>
        </v-container>
    </setting-layout>
</template>

<script>
import SettingLayout from "@/layouts/app/profile/SettingLayout";
import GradientButton from "@/components/app/button/GradientButton";
import {mapActions, mapState} from "vuex";

export default {
    name: "Language",
    data: () => ({
        model: 'English'
    }),
    computed: {
        languages() {
            let lang = [];
            for (const [code, text] of Object.entries(this.$t('languages.list'))) {
                lang.push({code, text})
            }
            return lang;
        },
        ...mapState({
            language: state => state.user.user.data.language,
            user: (state) => state.user.user.data,
        }),
    },
    methods: {
        async done() {
            this.sendLanguage({model: {language: this.model}})
                .then(() => {
                    if (this.model !== this.$i18n.locale) {
                        this.$i18n.locale = this.model
                        localStorage.setItem('noodzly_lang', this.model)
                    }
                    this.$store.commit('showSnackBar', this.$t('messages.success'))
                })
            // do somethings
        },
        ...mapActions([
            'sendLanguage',
            'getOwnMainUserData',
        ])
    },
    mounted() {

        if (typeof this.language !== 'undefined') {
            this.model = this.language
        } else {
            this.$auth.fetch().then((res) => {
                this.model = res.data.data.language
            })
        }

        this.$mixpanel.identifyUser(this.user.email);
        this.$mixpanel.pageViewed('Language');
    },
    components: {GradientButton, SettingLayout}
}
</script>

<style scoped>

</style>
